import { Outlet } from "react-router-dom";
import Drawer from "../Components/Drawer";

export default function Layout() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Drawer />
      <Outlet />
    </div>
  );
}
