import React, { useState } from "react";
import style from "./style.module.css";
import HeaderBar from "../../Components/HeaderBar";

const Projects = () => {
  return (
    <div>
      <HeaderBar title="Projects" />
    </div>
  );
};

export default Projects;
