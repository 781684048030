import React, { useState } from "react";
import style from "./style.module.css";
import HeaderBar from "../../Components/HeaderBar";

const Home = () => {
  return (
    <div>
      <HeaderBar title="Code Quality Control" />
    </div>
  );
};

export default Home;
