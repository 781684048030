import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import style from "./style.module.css";
import logo from "../../Assets/Images/logo.png";
import Cookie from "universal-cookie";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import WorkIcon from "@mui/icons-material/Work";
import LogoutIcon from "@mui/icons-material/Logout";

const DashboardButton = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div>
      {data.map((x, i) => (
        <DashboardButtonHelper
          id={i}
          key={i}
          title={x.title}
          icon={x.icon}
          selectedIndex={selectedIndex}
          callback={(id) => {
            setSelectedIndex(id);
            if (x.onClick) {
              x.onClick();
            }
          }}
        />
      ))}
    </div>
  );
};

const DashboardButtonHelper = ({ callback, title, icon, id, selectedIndex }) => {
  const clicked = selectedIndex === id;

  return (
    <div
      onClick={() => {
        callback(id);
      }}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        backgroundColor: clicked ? "#2E789B" : "#0f3c50",
        color: clicked ? "#00D000" : "#828282",
        padding: "13px 0",
        paddingLeft: 20,
        color: "#CACACA",
      }}
    >
      <div style={{ paddingTop: 4 }}>{icon}</div>
      <div className={style.textBtn}>{title}</div>
    </div>
  );
};

const Drawer = () => {
  const cookies = new Cookie();
  const navigate = useNavigate();

  return (
    <div className={style.drawerContainer}>
      <div
        style={{
          display: "flex",
          flex: 1,
          height: "100vh",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            className={style.logoContainer}
            onClick={() => {
              window.location.href = "https://intra.peppermint.company/";
            }}
          >
            <img src={logo} style={{ width: 130 }} />
          </div>
          <div>
            <div className={style.section}>
              <p style={{ fontSize: "14px", fontWeight: 700 }}>Manage</p>
            </div>
            <DashboardButton
              data={[
                {
                  title: "Individual",
                  icon: <SupervisedUserCircleIcon />,
                  onClick: () => navigate("/"),
                },
                { 
                  title: "Projects",
                  icon: <WorkIcon />, 
                  onClick: () => navigate("/projects") 
                },
              ]}
            />
          </div>
        </div>
        <div
          className={style.logout}
          onClick={() => {
            localStorage.removeItem("token");
            cookies.remove("email", { domain: ".peppermint.company" });
            cookies.remove("password", { domain: ".peppermint.company" });
            cookies.remove("token", { domain: ".peppermint.company" });
            cookies.remove("*", { path: "/", domain: ".peppermint.company" });
            cookies.set("isSignedOut", true, { path: "/", domain: ".peppermint.company" });
            window.location.href = "https://intra.peppermint.company/";
          }}
        >
          <div>
            <LogoutIcon />
          </div>
          <div style={{ marginLeft: 10 }}>Logout</div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
