import React, { useState, useEffect } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";

const HeaderBar = (props) => {
  const navigate = useNavigate();
  const { title, isAllowBack, destinationPath } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid #ECECEC",
          padding: 22,
          textAlign: "center",
          alignItems: "center",
        }}
      >
        {isAllowBack && (
          <div>
            <IconButton
              onClick={() => {
                navigate(destinationPath);
              }}
            >
              <ArrowBackRoundedIcon style={{ color: "black" }} />
            </IconButton>
          </div>
        )}
        <div style={{ marginLeft: 0, fontWeight: "bold", fontSize: 23 }}>{title}</div>
      </div>
    </div>
  );
};

export default HeaderBar;
